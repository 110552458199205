import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Mentor  Mentee Relationship; Becoming stronger in life's journey",
  "author": "Anthony",
  "date": "2020-01-27T08:20:10.000Z",
  "categories": ["mentorship", "Personal-Professional Growth"],
  "slug": "mentor-mentee-relationship-becoming-stronger-in-lifes-journey",
  "draft": false,
  "meta_title": "Mentor  Mentee Relationship; Becoming stronger in life's journey",
  "cover": "../../images/wp_blog_images/consulting-3055704_1920.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Christina (Purepost Marketing Manager) and I were discussing mentoring a few weeks ago and something struck me. Since I’ve been mentoring Veteran entrepreneurs over the past few years - I mentor out of selfishness.`}</p>
    <p>{`What I mean by this is if I’m going to devote an hour phone call or coffee every month or two - I need to:`}</p>
    <ol>
      <li parentName="ol">{`Know they will take some of my advice and apply it to make themselves better either professionally or personally`}</li>
      <li parentName="ol">{`Become a better person from my relationship with the mentee`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1280px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.1875%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwQA/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAATqjUPR6z//EABkQAAIDAQAAAAAAAAAAAAAAAAECAAMSEf/aAAgBAQABBQJByMcw8J1lUuJrn//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAICAwAAAAAAAAAAAAAAAAABESEQEjL/2gAIAQEABj8CnZEpUdIdWQ1j/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFxQVH/2gAIAQEAAT8hsnSMt5QbV0Swd0yx0+RVYTa+xn//2gAMAwEAAgADAAAAEAgv/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EB1Sf//EABcRAAMBAAAAAAAAAAAAAAAAAAABEUH/2gAIAQIBAT8Qa0h//8QAHRABAAICAgMAAAAAAAAAAAAAAREhAEExUWGB0f/aAAgBAQABPxAwLygvprHKB8qKmiLPOTOql5fmVSWgPR6O8IpXVN+yMTOrvjP/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "consulting 3055704 1920",
          "title": "consulting 3055704 1920",
          "src": "/static/3e293f93736c66dad6ab4a91e283b28e/eea4a/consulting-3055704_1920.jpg",
          "srcSet": ["/static/3e293f93736c66dad6ab4a91e283b28e/cb69c/consulting-3055704_1920.jpg 320w", "/static/3e293f93736c66dad6ab4a91e283b28e/c08c5/consulting-3055704_1920.jpg 640w", "/static/3e293f93736c66dad6ab4a91e283b28e/eea4a/consulting-3055704_1920.jpg 1280w", "/static/3e293f93736c66dad6ab4a91e283b28e/0f98f/consulting-3055704_1920.jpg 1920w"],
          "sizes": "(max-width: 1280px) 100vw, 1280px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{` Image by Gerd Altmann from Pixabay`}</p>
    <p>{`The second point is where I’ll focus.`}</p>
    <p>{`I’m currently mentoring three professionals ranging from 26 to 32 years of age. I’m 43. I seek out younger professionals that I see promise in because they also teach and coach me through obstacles along my life’s journey. When I’m mentoring or coaching them on an issue, it forces me to look at myself and determine if I’m practicing what I’m preaching.`}</p>
    <p>{`As the relationship evolves, I soon feel comfortable texting, calling, zooming, or meeting up when I need some assistance. And you know what, my mentees are all too happy to return the favor. The relationship also helps me to stay relevant with technology, music, fashion, etc.`}</p>
    <p>{`If the Mentor `}{`<`}{`>`}{` Mentee relationship is truly a good fit, both parties become stronger in their life’s journey.`}</p>
    <p>{`A big thank you to those I mentor - I’m better because of you :-)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      